<template>
  <div class="banner">
    <div class="banner-content">
      <div class="banner-text">助力企业数字化转型</div>
      <div class="banner-subtitle">从业务所需技能到领导力发展，从软件开发到AIGC，应有尽有</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bannerBox',
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.banner {
  box-sizing: border-box;
  margin-top: $robin-navbar-height-web;
  width: 100%;
  .banner-content {
    width: 100%;
    height: 513px;
    // background: url('../../../assets/banner-bac.png') no-repeat center;
    // background-size: 100% 100%;
    // background: linear-gradient(to bottom, #bde1fe, #fff 50%, #ffffff 100%);
    background: linear-gradient(to bottom, #bde1fe 0%, #e2f0ff 50%, #ffffff 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .banner-text {
      margin-top: 62px;
      font-weight: 600;
      font-size: 32px;
      color: #0077f9;
    }
    .banner-subtitle {
      font-weight: 600;
      font-size: 16px;
      color: #333333;
      margin-top: 19px;
    }
  }
}
</style>

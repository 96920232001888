<template>
  <div class="home">
    <div class="home-banner">
      <banner-box></banner-box>
    </div>
    <div class="home-content">
      <course-list></course-list>
    </div>
  </div>
</template>

<script>
import CourseList from './components/courseList.vue'
import BannerBox from './components/bannerBox.vue'
import { mapActions, mapGetters } from 'vuex'
import { getToken, getStoreToken, setStoreToken } from '@/utils/auth'
import { setUserInfo, getUserInfo, setPermissions, getPermissions } from '@/utils/user'
import { getInfo } from '@/api/login'
export default {
  name: 'Home',
  components: {
    CourseList,
    BannerBox
  },
  created() {
    if (getToken() != undefined) {
      this.getInfo()
    }
  },
  methods: {
    ...mapActions({
      SET_USERINFO: 'SET_USERINFO',
      SET_PERMISSIONS: 'SET_PERMISSIONS'
    }),
    // 获取用户详情
    getInfo() {
      getInfo()
        .then(res => {
          let user = res.data.user
          let permissionFlag = ['shopping:record:pay', 'order:list:pay', 'shopping:businessVoucher:list']
          let permissions = res.data.permissions.some(permission => {
            return '*:*:*' === permission || permissionFlag.includes(permission)
          })
          let { userName, companyName, userId } = res.data.user
          let avatar = user.avatar ?? require('@/assets/no-avatar.png')
          let userInfo = {
            name: userName,
            avatar: avatar,
            companyName,
            userId
          }
          this.SET_USERINFO(userInfo)
          this.SET_PERMISSIONS(permissions)
          setUserInfo(JSON.stringify(userInfo))
          setPermissions(JSON.stringify(permissions))
          setStoreToken(getToken())
        })
        .catch(err => {})
    }
  }
}
</script>
<style scoped lang="scss">
.home {
  position: relative;
  border: 1px solid transparent;
  // overflow-x: hidden;
  .home-banner {
    position: absolute;
    width: 100%;
  }
  &-content {
    position: relative;
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0 auto;
    // height: 600px;
    // border: 1px solid red;
    margin-top: $robin-navbar-height-web;
  }
}
</style>
